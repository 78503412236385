import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'
import { planPrice } from 'common/utils/planPrice'

const meeting1 = require('public/images/meeting-room1.png').default.src
const meeting2 = require('public/images/meeting-room2.png').default.src
const defaultStrings = {
  averageSetupTime: 'Average set up time of ten minutes with no setup fees',
  averageSetupTimeDescription: `<p><strong>
How long does setting up an account take?
</strong></p><p>
When you have inputted your card details, and we have verified your ID and proof of address for all beneficial owners, it takes an average time of ten minutes to approve your account. This is only between 09:00 and 18:00 UK time Monday to Friday. If you register outside of those times, the request will be handled within the first few hours of the next working day.
</p><p><strong>
How much does it cost to set up an account?
</strong></p><p>
Setting up an account is free. There are no setup fees when joining the Hoxton Mix Virtual Office
</p>`,
  coWorkingMembership: 'Meeting Room Access',
  coWorkingMembershipDescription: 'Meeting room access on a per hour basis',
  coWorkingMembershipDescription2:
    'Meeting Room & Hot desk access on a per hour basis for when you visit the UK.',
  collaborative: 'Convenient & Collaborative',
  collaborativeDescription:
    'Access your mail anytime, anywhere, from any device. Share digitised PDFs of your business mail with your team, no matter their location.',
  collectPrimeCentralLondonAddress:
    'Prime Central London Address and collection point',
  collectSeoDescription:
    'Virtual Office Collect a London business address to impress from 49p per day with Same-day setup and NO Postage Fees.',
  collectSeoTitle:
    'Virtual Office Collect: Mail Pickup & London Registered Address | Hoxton Mix',
  companiesHouseRegisteredAddress:
    'Companies House official company registered address included for free',
  companiesHouseRegisteredAddressDescription: `<p>
You can use the Hoxton Mix Virtual Office address as the legal registered address for your business with Companies House. This is fully included in the prices of all our plans. You do not need to pay anything extra.
</p>`,
  emailAlerts: 'Email alerts for every piece of post',
  emailAlertsDescription: `<p>
Every time you receive a piece of post we send you an email alert within one full working day. This ensures you are always on top of your business’ needs.
</p>`,
  flexibleMonthToMonthTerm: 'Flexible Month-to-Month Term',
  flexibleMonthToMonthTerm2: 'Flexible month-to-month term',
  flexibleMonthToMonthTermDescription:
    '<span class="d-xl-block">You can cancel your plan at any time, and you will not</span> be charged anything beyond the billing period you are in.',
  flexibleMonthToMonthTermDescription2:
    '<span class="d-xxl-block">You can cancel your plan at any time, and you will not be charged</span> anything beyond the billing period you are in.',
  flexiblePostage: 'Flexible postage',
  flexiblePostageDescription:
    'No matter where you are in the world, if you need to receive the physical copies of your mail, we can forward it to you. We charge £2.50 for a standard letter and £8.00 for a large letter.',
  flexibleScans: 'Flexible scans',
  flexibleScansDescription:
    'If you request the contents to be opened and scanned, those requests are usually completed within a few hours or less and costs just £1.00 per entire letter.',
  forwardPrimeCentralLondonAddress:
    '<span class="d-block">Prime Central London</span> Address with <span class="d-block">Mail Forwarding service within one business day</span>',
  forwardSeoDescription:
    'Use our exclusive London address as your business mailing address with mail handling and forwarding within one business day.',
  forwardSeoTitle:
    'Virtual Office Forward. Mail forwarding services in London | Hoxton Mix',
  freePostHandling: 'Generous Free Post Handling Included',
  freePostHandlingDescription:
    "For our Collect and Scan plans, we include the first 40 items of post each month free of charge, covering 99.9% of our customers' needs. Should you exceed this amount, our overage fees schedule applies.",
  freePostHandlingDescriptionSP: `<p>Customers receiving mail forwarding services on a fixed price plan are subject to a fair use policy based on the following allocation:</p>
<p><strong>Small Letters (up to 100g):</strong><br>
• Maximum of 15 letters per month.</p>
<p><strong>Large Letters:</strong><br>
• Up to 100g: Maximum of 10 large letters per month.</p>
<p>If a customer exceeds the fair usage limit for any category per month, any additional items will be charged per item according to the current Royal Mail rates, with a 50p handling charge per letter.</p>`,
  freePostHandlingSP: 'Generous Free Post Handling Included',
  getInTouch:
    'A London business forwarding address forwarded to you at an address of your choosing.',
  getYourMailNoMatterWhereYouAre:
    'Get your mail, no matter <br class="d-sm-none"/> where you are',
  getYourMailNoMatterWhereYouAreDescription:
    'With a virtual office address, you’ll get your mail scanned and emailed to you so you can stay on top of your correspondence wherever you are.',
  gettingYouSetup: '<span class="d-block">Getting you</span> set up',
  gettingYouSetup2: '<span class="d-lg-block">Getting you</span> set up',

  gettingYouSetupCollectDescription: `<p class="mb-lg-4 mb-3">
Once you have signed up as a Virtual Office Forward customer, a member of the Hoxton Mix team will contact you to get you up and running. We will need proof of ID - either a passport or driving license and a recent utility bill. Your account will be active once we receive and successfully verify your documents.
</p><p class="mb-lg-4 mb-3">
 Check out our Proof of ID and address document checklist for more information.
</p><p><strong>
Your new London address will be: Your Company Name, 3rd Floor, 86-90 Paul Street, London EC2A 4NE.
</strong></p>`,
  gettingYouSetupForwardDescription: `<p>
Once you have signed up as a Virtual Office Forward customer, a member of the Hoxton Mix team will contact you to get you up and running. We will need proof of ID - either a passport or driving license and a recent utility bill. Your account will be active once we receive and successfully verify your documents.
</p><p>
Check out our Proof of ID and address document checklist for more information.
</p><p><strong>
Your new London address will be: Your Company Name, 3rd Floor, 86-90 Paul Street, London EC2A 4NE.
</strong></p>`,
  haveQuestionsAboutPlan: 'Have questions about our {0} plan?',
  heroCollect:
    '<span class="d-block">A prestigious London</span> <span class="d-block">business address without</span><span class="d-block">the high costs</span>',
  heroForward:
    'A Prime London business <br class="d-block d-xxxxl-none"/> address and <br class="d-xxxxl-block d-none"/> Mail Forwarding service <br/> within one business day.',
  heroOfficial:
    'Keep your home address private <br/> and look professional with a <br/> Prime London business address.',
  heroPhone:
    'A Prime London virtual <br class="d-block d-xxl-none"/> phone <br class="d-xxl-block d-none"/> number for your <br class="d-block d-xxl-none"/> business with <br class="d-block"/> call forwarding.',
  heroScanLite:
    '<span class="d-xxxxl-block"><span class="d-block d-xxxxl-inline">We scan and send your mail to your</span> inbox as high-resolution PDFs</span><br class="d-block d-xxxxl-none"/><span class="d-xxxxl-block"><span class="d-block d-xxxxl-inline">  with full-text search.</span></span>',
  heroScanPro:
    'We scan, email and <br class="d-block d-xxxxl-none"/> forward the hard <br class="d-xxxxl-block d-none"/> copies <br class="d-block d-xxxxl-none"/> to you by post within <br/>one business day.',
  howDoesItWork: '<span class="d-block">How does</span> it work?',
  howDoesItWork2: '<span class="d-lg-block">How does</span> it work?',
  howDoesItWorkCollectDescription: `<p class="mb-lg-4 mb-3">
After your account is activated, we will process all your mail at our secure facility. Every time you receive a piece of mail, we send you a notification and let you know that we’ve got some post waiting to be collected.
</p><p class="mb-lg-4 mb-3">Then arrange a collection time to suit your busy schedule. 
</p><p><strong>Our virtual office opening hours are 09:00 - 18:00 from Monday - Friday.</strong></p>`,
  howDoesItWorkForwardDescription: `<p>
After your account is activated, we will process all your mail at our secure facility. Every time you receive a piece of mail, we send you a notification and forward all your mail to your private address within one business day of receiving it.
</p>`,
  importantDocumentHandling: 'Important document handling',
  importantDocumentHandlingDescription:
    'Storage, Pick Up Point, and forwarding available for essential items like bank cards.',
  keepYourHomeAddressPrivate: 'Keep your home address private',
  keepYourHomeAddressPrivateDescription:
    'First impressions can be crucial for a business, and it’s not always convenient to register your home address on the public register.',
  legalAddressForHMRC:
    'Legal address for HMRC correspondence included for free',
  legalAddressForHMRCDescription: `<p>
You can use the Hoxton Mix Virtual Office address as the legal registered address for your business for all your HMRC correspondents. This is fully included in the prices of all our plans. You do not need to pay anything extra.
</p>`,
  mailForwardingSlogan:
    '<span>UK Mail</span><span class="color-purple4"> Forwarding</span><span> within one business day</span>',
  mailHandling: 'Mail Handling – Drop Off/Pick Up Point',
  mailHandlingDescription:
    '<span class="d-xl-block">When we receive your post, we will notify you via email. You have 30 days to collect it, or you can pay a fee to have it scanned or forwarded.',
  mailHandlingEmail: 'Mail Handling, Scanned and Emailed',
  mailHandlingEmailDescription:
    'We scan and email all your post to you the within one business day.',
  mailHandlingSlogan:
    '<span>Mail</span><span>Handling</span><span>Drop off</span><span>&amp; Pickup</span>',
  monthToMonthTerm: 'Month-to-Month Term',
  monthToMonthTermDescription: 'Cancel at any point.',
  noHandlingCharges: 'No Handling Charges',
  noHandlingChargesDescription:
    '<span class="d-xl-block">Unlike other providers, our price is all you will pay,</span> regardless of how much post you receive.',

  noHandlingCosts: 'Generous Free Postage Included',
  noHandlingCostsDescription: `<p>Customers receiving mail forwarding services on a fixed price plan are subject to a fair use policy based on the following allocation:</p>
<p><strong>Small Letters (up to 100g):</strong><br>
• Maximum of 15 letters per month.</p>
<p><strong>Large Letters:</strong><br>
• Up to 100g: Maximum of 10 large letters per month.</p>
<p>If a customer exceeds the fair usage limit for any category per month, any additional items will be charged per item according to the current Royal Mail rates, with a 50p handling charge per letter.</p>`,
  noHandlingFees: 'No Handling Charges',
  noHiddenCharges: 'No Hidden Charges',
  noHiddenChargesDescription:
    'Our monthly price is all you will pay, regardless of how much mail you receive.',
  noPostageCharges: 'No Postage Charges',
  noPostageChargesDescription:
    '<span class="d-xxl-block">Unlike other providers, our price is all you will pay, we do not require</span> a postage deposit.',
  optionalSecurity: 'Optional security',
  optionalSecurityDescription: 'Letter Shredding service.',
  optionalSecurityDescription2:
    'On request, we securely shred documents onsite. Shredded waste is baled and recycled.',
  phoneSeoDescription:
    'Impress your clients with a dedicated virtual phone service from Hoxton Mix including unlimited calls from just £5.82 a month.',
  phoneSeoTitle: 'Virtual Office Phone System | Hoxton Mix',
  primeBusinessAddressInTheHeartOfOldStreet:
    'A prime business address in the heart of Old Street, Shoreditch.',
  primeLondonBusinessAddressSlogan:
    '<span>A prime</span><span class="color-purple4">LONDON</span><span>business address</span>',
  primeLondonMailingAddress: 'Prime London Mailing Address',
  primeLondonNumber: 'Prime London 020 number',
  primeMailingAddress: 'Private & Prestigious Address',
  primeMailingAddressDescription:
    '<span class="d-xl-block">Your Company Name, 3rd Floor, 86-90 Paul Street,</span> <span class="d-lg-none">London EC2A 4NE.eet, </span>London EC2A 4NE.',
  purchasingVirtualOfficeCollect:
    '<span class="d-block">Purchasing Virtual</span> Office Collect',
  purchasingVirtualOfficeCollectDescription: `<p class="mb-lg-4 mb-3">
Once you have chosen the Virtual Office Collect plan, it takes less than 60 seconds to check out online securely.
 </p><p class="mb-lg-4 mb-3">
  We offer a fully flexible month-to-month term. In addition to the month-to-month billing, if you subscribe to a yearly plan, you receive a two months free bonus, which is a 20% saving compared to monthly billing. 
</p>
 We accept major international debit or credit cards, including Visa, MasterCard, PayPal and American Express.
 </p>`,
  purchasingVirtualOfficeForward: 'Purchasing Virtual Office Forward',
  purchasingVirtualOfficeForwardDescription: `<p>
Once you have chosen the Virtual Office Forward plan, it takes less than 60 seconds to check out online securely. 
 </p><p>
We offer a fully flexible month-to-month term. In addition to the month-to-month billing, if you subscribe to a yearly plan, you receive a two months free bonus, which is a 20% saving compared to monthly billing.
</p>
 We accept major international debit or credit cards, including Visa, MasterCard, PayPal and American Express.
 </p>`,
  realTimenotifications: 'Real-Time Notifications',
  receiveYourBusinessLetters: 'Receive all your business letters',
  receiveYourBusinessLettersDescription: `<p>You can use the Hoxton Mix Virtual Office to receive all your business’ post. This is fully included in the prices of all our plans. You do not need to pay anything extra.
</p>`,
  rentMeetingRooms: `Rent meeting rooms from ${planPrice.hourly[
    'meeting-rooms'
  ].replace('.00', '')}/hour`,
  rentMeetingRoomsDescription: `<p>
<img loading="lazy" width="250" src="/_next/image?url=${encodeURIComponent(
    meeting1,
  )}&w=1920&q=75"/>
<img loading="lazy" width="250" src="/_next/image?url=${encodeURIComponent(
    meeting2,
  )}&w=1920&q=75"/>
</p><p>
Our meeting rooms are located on the 3rd floor with disabled access via our lift. Filter coffee & tea is provided free of charge and our friendly reception team are on hand to show guests and delegates into the meeting room
</p><p><strong>
What is included with a meeting room visit?
</strong></p><ul><li>
Meeting table for up to 8 people
</li><li>
Fibre Optic Broadband/Wi-Fi
</li><li>
4K Screen
</li><li>
Whiteboard and Marker Pens
</li><li>
Filter coffee & tea provided free of charge
</li><li>
Air conditioning
</li></ul><p><strong>
How much do meeting rooms cost?
</strong></p><p>
Meeting rooms cost ${planPrice.hourly['meeting-rooms'].replace(
    '.00',
    '',
  )} per hour.
</p><p><strong>
How easy is it to book a meeting room?
</strong></p><p>
Booking a meeting room is very easy. Just send an email to <a href="mailto:help@hoxtonmix.com">help@hoxtonmix.com</a> with the date and time you desire. Availability is decided on a first come, first served basis.
</p>
  `,
  safeCancelling: 'Safe Cancelling at any time',
  safeCancellingDescription: `<p>This means you don’t have to worry about nasty contract renewal surprises.
</p><p>
<strong>When can I cancel a monthly plan?</strong>
</p><p>
The monthly renewal subscription fees will continue to be billed to the Payment Method you provided, automatically until cancelled. You must cancel your subscription before it renews each month to avoid billing of the next month's subscription fee to the Payment Method you provided.
</p><p>
Refunds cannot be claimed for any partial-month subscription period.
</p><p><strong>
When can I cancel an annual plans?
</strong></p><p>
You can cancel at any point and you will not be charged for any subsequent years. We are not able to offer pro-rata refunds for the annual plan. This allows us to offer two months free when signing up to the annual plan. If you cancel within seven days of your most recent invoice we will refund you for that invoice should you wish to terminate your service immediately.
</p><p><strong>
How easy is it to cancel?
</strong></p><p>
Very easy. Just send an email to our support team (<a href="mailto:help@hoxtonmix.com">help@hoxtonmix.com</a>) from your registered email address asking for a cancellation and we will cancel your account.
</p>`,
  sameDayMailHandling:
    'Mail Handling and Mail Forwarding within one business day',
  sameDayMailHandlingDescription:
    '<span class="d-xxl-block">We forward all your physical mail to your private address within one business day.',
  sameDayScanSlogan:
    '<span class="color-purple4">Scan</span><span> and email service</span>',
  sameDayScanSlogan2:
    '<span class="color-purple2">Scan</span><span> and mail forwarding</span>',
  sameDayService: 'Service within one business day',
  sameDayServiceDescription:
    'We scan and send your mail to your inbox, mobile app, and customer portal within one business day. ',
  sameDayServiceDescription2:
    'We scan, email, and forward the hard copies to you by post within one business day.',
  scanLitePrimeCentralLondonAddress:
    '<span class="d-block">Prime Central London</span> <span class="d-block">Address with mail scanning within one business day</span>',
  scanLiteSeoDescription:
    'With Scan Lite, you can use our exclusive London address as your business mailing address. We scan and email all your post to you within one business day of receipt.',
  scanLiteSeoTitle:
    'Virtual Office Scan Lite. We Scan, Email & Shred | Hoxton Mix',
  scanProPrimeCentralLondonAddress:
    '<span class="me-xxxl-n10 d-xxxl-block">Prime Central London Address</span> <span class="me-xxxl-n10 d-xxxl-block">with mail scanning within one business day</span> and <span class="d-sm-inline d-block">mail forwarding</span>',
  scanProSeoDescription:
    'Use our exclusive London address as your business mailing address. We scan, email, and forward the hard copies to you by post within one business day.',
  scanProSeoTitle: 'Virtual Office Scan Pro | Hoxton Mix',
  scanningWithFullText: 'No More Junk',
  scanningWithFullText2: 'Scanning With Full-Text',
  scanningWithFullTextDescription:
    "We'll filter out junk mail and shred what you don't want. ",
  scanningWithFullTextDescription2:
    'Search All scans are done in high resolution then converted to PDF with full-text search.',
  secureShredding: 'Secure Shredding',
  secureShreddingDescription:
    'Physical copies are securely shredded under a Certificate of Data Destruction. Shredded waste is baled and recycled.',
  setupInTenMinutes: 'Setup in 10-minutes or less',
  stopJunkMail: 'Stop junk mail',
  stopJunkMailDescription:
    'Our processing team filters junk mail, meaning you receive <br class="d-xxl-block d-none"/> official <br class="d-sm-none"/>government post.',
  storageAndMailHandlingIncluded: 'Storage and Mail handling included',
  storageAndMailHandlingIncludedDescription:
    'Prefer to collect your post? You can opt to have the post stored securely then collect it yourself from a dedicated pick-up point.',
  topQualityPostCode:
    'Top quality post code with excellent office building appearance',
  topQualityPostCodeDescription: `<p><strong>Where is the Hoxton Mix Virtual Office address located?
</strong></p><p>
The Hoxton Mix Virtual Office is a prime address in central London. It is close to Old Street, Moorgate and Liverpool Street tube stations.
</p><p><strong>
What does the office building look like?
</strong></p><p>
  <img loading="lazy" class="d-block" src="/images/office.png"/>
  <small>The Hoxton Mix building is the white and grey one. Actual Google Street View image.</small>
</p><p>
This is a photo of the exterior of our building. This image is visible on Google Street view when people look up your address.
</p><p><strong>
What will my new address be?
</strong></p><p>
Your Company Name / Your Name,<br/>
Third Floor,<br/>
86-90 Paul Street,<br/>
London,<br/>
EC2A 4NE<br/>
</p>`,
  transferToLandline: 'Transfer to UK/EU/US Landline',
  transferToUKMobiles: 'Transfer to UK Mobiles',
  twelveMonthsForPriceOfTenMonths: '12 Months for Price of 10 Months',
  ukTransfer: 'UK/EU/US Transfer',
  useYourAddress: 'Use as your Registered Office Address',
  useYourAddressDescription:
    '<span class="d-xl-block">Register our address with Companies House as your</span> registered company address.',
  useYourAddressDescription2:
    '<span class="d-xxl-block">Register our address with Companies House as your registered</span> company address.',
  virtualOfficePhoneTelephoneOnly: 'Virtual Office Phone - Telephone only',
  weDeliver: 'We deliver',
  weDeliverDescription:
    '<p>We send your mail to your inbox, <a href="/mobile/">mobile app</a>, and customer portal.</p>',
  weDeliverProDescription:
    '<p>Receive high-resolution PDFs with full text search, and we forward the physical post to your private address within one business day of receiving it.</p>',
  weGetYourPost: 'We get your post',
  weGetYourPostDescription:
    '<p>We receive your post, ensuring your privacy and providing you with a prestigious London address.</p>',
  weScanAndDigitise: 'We digitise it for you',
  weScanAndDigitiseDescription:
    'All scans are done in high resolution and converted to searchable PDFs.',
  yourAddressStaysTheSame: 'Your address stays the same',
  yourAddressStaysTheSameDescription:
    'With a virtual office address, no matter how often you move, <br class="d-xxl-block d-none"/> your registered address stays the same.',
}
export const planDetailStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
