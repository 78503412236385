import { FC } from 'react'
import { planDetailStrings } from 'common/strings/plan-detail-strings'
import Strings from 'project/localisation'
import { Utils } from 'common/utils'
import Icon from './Icon'

type SupportTextType = {
  name: keyof typeof Strings
}

const SupportText: FC<SupportTextType> = ({ name }) => {
  return (
    <>
      <div className={'d-flex justify-content-center px-lg-0 px-3'}>
        <div className={'me-1'}>
          <Icon
            name='bubble'
            height={22}
            width={22}
            style={{
              marginRight: 4,
              marginTop: -4,
            }}
          />
        </div>
        <div className={'text-medium text-medium--lt2'}>
          <p className={'mt-1 mb-lg-0 mb-2'}>
            {planDetailStrings.formatString(
              planDetailStrings.haveQuestionsAboutPlan,
              `${Strings[name]}`,
            )}
          </p>
          <span
            className='cursor-pointer text-decoration-none color-blue3'
            onClick={Utils.openChat}
          >
            {Strings.chatWithOneOfOurTeam}
          </span>
        </div>
      </div>
    </>
  )
}

export default SupportText
